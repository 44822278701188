import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/fracton-rec-black.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Fracton"
            style={{ width: '', height: '6em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/jp/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/jp/team">
                        Team
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/jp/magazine">
                        Web3 Magazine
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    {/* <li>
                      <Link className="navbar-item" to="/jp/news">
                        News
                      </Link>
                    </li> */}
                    
                    <li>
                      <Link className="navbar-item" to="/jp/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/jp/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="https://drive.google.com/drive/folders/1xg8wCrjUptmgEDRPShN3_5uucBtjB5u0">
                        Press Kit
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://www.facebook.com/FractonVentures/">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/wecandaoit">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                {/* <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a> */}
              </div>
            </div>
          </div>
          <div className="column is-4" style={{padding: '1rem 1rem 0', margin: '1rem 0'}} className="is-5">©Copyright2022 Fracton Ventures, Inc. All Rights Reserved. </div>
        </div>
        
      </footer>
    )
  }
}

export default Footer
